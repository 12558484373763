import React from 'react'
import DataService from '../../../../../config/DataService';
import { Api } from '../../../../../config/Api';
import Index from '../../../../Index';
import { useState } from 'react';
import { useEffect } from 'react';

const TotalEarnedCoin = ({id}) => {

  const [earnCoinDetails, setEarnCoinDetails] = useState({});
  const [moneyPaid, setMoneyPaid] = useState("");



  const getEarnCoin = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("userId", id);
    await DataService.post(Api.User.EarnCoinList, urlencoded).then((res) => {
      let response = res?.data?.data;
      let TotalBalance = res.data.data.wallet?.ammount;
      let TotalEarned =
        response?.totalWinnings +
        response?.todayWinnings +
        response?.earnedThroughReferrals;
      setMoneyPaid(TotalBalance - TotalEarned);
      setEarnCoinDetails(res?.data?.data);
    });
  };

  useEffect(() => {
    getEarnCoin();
  },[])
  return (
    <div>
       <Index.Box className="win-lose-main">
            <Index.Box className="card-main-head">
              Total Earned Coins (
              {earnCoinDetails?.wallet?.ammount
                ? Math.abs(earnCoinDetails?.wallet?.ammount)
                : 0}
              )
            </Index.Box>

            <Index.Box className="card-main-body">
              <Index.Box className="card-total">
                <Index.Typography className="left-name">
                  Earned through Referrals
                </Index.Typography>
                <Index.Typography className="right-data">
                  {earnCoinDetails?.earnedThroughReferrals
                    ? Math.abs(earnCoinDetails?.earnedThroughReferrals)
                    : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="card-total card-total-sub">
                <Index.Typography className="left-name">
                  Earned from Winning
                </Index.Typography>
                <Index.Typography className="right-data">
                  {earnCoinDetails?.totalWinnings
                    ? Math.abs(earnCoinDetails?.totalWinnings)
                    : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="card-total card-total-sub">
                <Index.Typography className="left-name">
                  Everyday Earned
                </Index.Typography>
                <Index.Typography className="right-data">
                  {earnCoinDetails?.todayWinnings
                    ? earnCoinDetails?.todayWinnings
                    : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="card-total card-total-sub">
                <Index.Typography className="left-name">
                  Balance from Money Paid
                </Index.Typography>
                <Index.Typography className="right-data">
                  {moneyPaid ? moneyPaid : 0}
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
    </div>
  )
}

export default TotalEarnedCoin