import React, { useEffect, useState } from "react";
import Index from "../../../../component/Index";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import {
  addEditTermsAndCodition,
  CmsDetails,
} from "../../../../redux/auth/action";
import { ToastContainer } from "react-toastify";
import ErrorShow from "../../../../component/common/ErrorShow";

export default function TermsAndCondition() {
  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });

  const CMS = useSelector((state) => {
    return state.adminReducer.Cms;
  });

  const dispatch = useDispatch();
  const [description, setDiscription] = useState(CMS[0]?.termsAndCondition?.description);
  const [descriptionError, setDiscriptionError] = useState("");

  useEffect(() => {
    dispatch(CmsDetails());
  }, []);

  const handleSubmit = () => {
    if (description == "") {
      setDiscriptionError("Please Enter Description");
    } else {
      setDiscriptionError("");
    }

    if (description !== "") {
      const urlencoded = new URLSearchParams();
      urlencoded.append("description", description);
      dispatch(addEditTermsAndCodition(urlencoded, token));
    }
  };

  return (
    <>
      <Index.Box className="dashboard-content">
        <ToastContainer />
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Terms and Condition
          </Index.Typography>
        </Index.Box>
        <Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Description
            </Index.FormHelperText>
            <Index.Box className="set-ck-editor">
              <CKEditor
                editor={ClassicEditor}
                data={CMS?.[0] ? CMS?.[0]?.termsAndCondition?.description : "-"}
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDiscription(data);
                  setDiscriptionError('')
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </Index.Box>

            <ErrorShow error={descriptionError} />
          </Index.Box>
          <Index.Box className="save-btn-main border-btn-main">
            <Index.Button
              className="save-user-btn border-btn"
              onClick={() => handleSubmit()}
            >
              <img src={Index.Svg.save} className="user-save-icon"></img>Save
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
