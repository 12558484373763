import React from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import Index from "../Index";
import { useForm, Controller } from "react-hook-form";
import DataService from "../../config/DataService";
import { Api } from "../../config/Api";
import { useLocation, useParams } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import ErrorShow from "../../component/common/ErrorShow";

export default function Otp() {
  const params = useParams();
  const navigate = Index.useNavigate();
  const location = useLocation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (newValue) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", params.id);
    urlencoded.append("otp", newValue.otp);

    await DataService.post(Api.User.verifyOtp, urlencoded).then((res) => {
      if (res.data.status == 200) {
        toast.success(res.data.message);
        setTimeout(() => {
          navigate("/reset-password", {
            state: { id: `${res.data.data._id}` },
          });
        }, 3000);
      } else {
        toast.error("Invalid Otp");
      }
    });
  };

  const renderButton = (buttonProps) => {
    return (
      <Index.Button
        className="btn-primary login-btn resend-btn"
        type="submit"
        {...buttonProps}
      >
        Resend
      </Index.Button>
    );
  };
  const renderTime = (remainingTime) => {
    return remainingTime == 0 ? (
      <span></span>
    ) : (
      <span className="resend-btn-box">{remainingTime} seconds remaining</span>
    );
  };

  return (
    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main  set-login-box-back">
          <Index.Box className="login-left-bg">
            <img src={Index.Png.ss_logo} className=""></img>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <Index.Box className="admin-login-inner">
                <Index.Typography
                  component="h2"
                  variant="h2"
                  className="admin-wel-text"
                >
                  Verify OTP
                </Index.Typography>
                <Index.Typography
                  component="p"
                  variant="p"
                  className="admin-sign-para common-para"
                >
                  We dent you an OTP to your registered Email
                </Index.Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Index.Box className="otp-flex-main otp-flex-main2">
                    <Controller
                      name="otp"
                      control={control}
                      rules={{
                        required: "Otp is required",
                        minLength: {
                          value: 4,
                          message: `Min length is 4 characters`,
                        },
                        validate: (val) => {
                          if (location?.state?.otp != val) {
                            return "Invalid Otp";
                          }
                        },
                      }}
                      error={!!errors?.otp}
                      render={({ field: { onChange, value } }) => (
                        <MuiOtpInput
                          length={4}
                          value={value}
                          onChange={onChange}
                          name="otp"
                        />
                      )}
                    />
                  </Index.Box>
               
                  <ErrorShow error={errors.otp?.message}/>
                  {/* <ResendOTP
                    renderButton={renderButton}
                    renderTime={renderTime}
                  /> */}

                  <Index.Box className="btn-main-primary login-btn-main">
                    <Index.Button
                      className="btn-primary login-btn"
                      type="submit"
                    >
                      Verify Otp
                    </Index.Button>
                  </Index.Box>
                </form>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <ToastContainer />
    </div>
  );
}
