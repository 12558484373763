import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Index from "../Index";

export default function Sidebar(props) {
  let location = useLocation();
  const [openCMS, setOpenCMS] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [screen, setScreen] = useState("");

  const handleCMS = () => {
    setOpenCMS(!openCMS);
  };
  const hanldeReport = () => {
    setOpenReport(!openReport);
  };

  useEffect(() => {
    if (window.screen.width < 768) {
      setScreen("Mobile");
    } else {
      setScreen("Desktop");
    }
  }, [window.screen.availHeight]);

  const { pathname } = useLocation();
  console.log(pathname, "pathname");
  useEffect(() => {
    if (
      pathname == "/admin/cms/about-us" ||
      pathname == "/admin/cms/privacy-policy" ||
      pathname == "/admin/cms/terms-and-condition"
    ) {
      setOpenCMS(true);
    } else {
      setOpenCMS(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (
      pathname == "/admin/cms/about-us" ||
      pathname == "/admin/cms/privacy-policy" ||
      pathname == "/admin/cms/terms-and-condition"
    ) {
      setOpenReport(true);
    } else {
      setOpenReport(false);
    }
  }, [pathname]);

  return (
    <>
      <Index.Box
        className={`admin-sidebar-main ${
          (screen == "Mobile" ? !props.open : props.open) ? "active" : ""
        }`}
      >
        <Index.Box className="admin-sidebar-inner-main">
          <Index.Box className="admin-sidebar-logo-main">
            <img
              src={Index.Png.ss_logo}
              className="admin-sidebar-logo"
              alt="logo"
            />
            <Index.Button
              onClick={() => props.setOpen(!props.open)}
              className="sidebar-close-btn"
            >
              <img src={Index.Png.close} className="close-icon" alt="logo" />
            </Index.Button>
          </Index.Box>

          <Index.Box className="admin-sidebar-list-main">
            <Index.List className="admin-sidebar-list">
              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/dashbord"
                  className={`${
                    location.pathname == "/admin/dashbord"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link "
                  }`}
                >
                  <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Dashboard
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/user-list"
                  className={`${
                    location.pathname == "/admin/user-list"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link "
                  }`}
                >
                  <img
                    src={Index.Svg.userlist}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  User List
                </Index.Link>
              </Index.ListItem>

                <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/transction-histroy"
                  className={`${
                    location.pathname == "/admin/transction-histroy"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link "
                  }`}
                >
                  <img
                    src={Index.Svg.userlist}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Transction Histroy
                </Index.Link>
              </Index.ListItem>


              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/room-list"
                  className={`${
                    location.pathname == "/admin/room-list"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link "
                  }`}
                >
                  <img
                    src={Index.Svg.editpage}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Room
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/payment-list"
                  className={`${
                    location.pathname == "/admin/payment-list"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link "
                  }`}
                >
                  <img
                    src={Index.Svg.editpage}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Payment
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/game-list"
                  className={`${
                    location.pathname == "/admin/game-list"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link "
                  }`}
                >
                  <img
                    src={Index.Svg.editpage}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Game{" "}
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/game-wise-bet"
                  className={`${
                    location.pathname == "/admin/game-wise-bet"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link "
                  }`}
                >
                  <img
                    src={Index.Svg.editpage}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Game Wise Bet
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/betList"
                  className={`${
                    location.pathname == "/admin/betList"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link "
                  }`}
                >
                  <img
                    src={Index.Svg.editpage}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Bet List
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/game-rules"
                  className={`${
                    location.pathname == "/admin/game-rules"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link "
                  }`}
                >
                  <img
                    src={Index.Svg.editpage}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Game Rule
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/request-list"
                  className={`${
                    location.pathname == "/admin/request-list"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link "
                  }`}
                >
                  <img
                    src={Index.Svg.userlist}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Call Requests
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem
                className="admin-sidebar-listitem admin-submenu-listitem-main"
                onClick={handleCMS}
              >
                <Index.Link className="admin-sidebar-link">
                  <img
                    src={Index.Svg.changepassword}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  CMS
                  {openCMS ? (
                    <Index.ExpandMore className="expandmore-icon" />
                  ) : (
                    <Index.ExpandLess className="expandless-icon" />
                  )}
                  <Index.Box className="submenu-main">
                    <Index.Collapse
                      in={openCMS}
                      timeout="auto"
                      unmountOnExit
                      className="submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        {/* <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link
                            to="/admin/cms/aboutus"
                            className={`${
                              location.pathname == "/admin/cms/aboutus"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link "
                            }`}
                          >
                            About Us
                          </Index.Link>
                        </Index.ListItem> */}
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link
                            to="/admin/cms/privacy-policy"
                            className={`${
                              location.pathname == "/admin/cms/privacy-policy"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link "
                            }`}
                          >
                            Privacy Policy
                          </Index.Link>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link
                            to="/admin/cms/terms-and-condition"
                            className={`${
                              location.pathname ==
                              "/admin/cms/terms-and-condition"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link "
                            }`}
                          >
                            Terms and Condition
                          </Index.Link>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Link>
              </Index.ListItem>

              <Index.ListItem
                className="admin-sidebar-listitem admin-submenu-listitem-main"
                onClick={hanldeReport}
              >
                <Index.Link className="admin-sidebar-link">
                  <img
                    src={Index.Svg.changepassword}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Report
                  {openReport ? (
                    <Index.ExpandMore className="expandmore-icon" />
                  ) : (
                    <Index.ExpandLess className="expandless-icon" />
                  )}
                  <Index.Box className="submenu-main">
                    <Index.Collapse
                      in={openReport}
                      timeout="auto"
                      unmountOnExit
                      className="submenu-collapse"
                    >
                      <Index.List
                        component="div"
                        disablePadding
                        className="admin-sidebar-submenulist"
                      >
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link
                            to="/admin/report/game-report"
                            className={`${
                              location.pathname == "/admin/report/game-report"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link "
                            }`}
                          >
                            Game Report
                          </Index.Link>
                        </Index.ListItem>
                        <Index.ListItem className="admin-sidebar-listitem">
                          <Index.Link
                            to="/admin/report/balance-report"
                            className={`${
                              location.pathname ==
                              "/admin/report/balance-report"
                                ? "admin-sidebar-link active"
                                : "admin-sidebar-link "
                            }`}
                          >
                            Coins Balance Report
                          </Index.Link>
                        </Index.ListItem>
                      </Index.List>
                    </Index.Collapse>
                  </Index.Box>
                </Index.Link>
              </Index.ListItem>

              {/* <Index.ListItem className="admin-sidebar-listitem">
                <Index.Link
                  to="/admin/report"
                  className={`${
                    location.pathname == "/admin/eport"
                      ? "admin-sidebar-link active"
                      : "admin-sidebar-link "
                  }`}
                >
                  <img
                    src={Index.Svg.dashboard}
                    alt="sidebar icon"
                    className="admin-sidebar-icons"
                  />
                  Report
                </Index.Link>
              </Index.ListItem> */}
            </Index.List>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
