import type from "./type";
import {
  loginApi,
  signupApiHandler,
  getAllUserApi,
  getTransactionApi,
  cmsDetailsApi,
  activeDeactiveUserApi,
  addUpdateGameRulesApi,
  addEditGameApi,
  getAllGamesApi,
  addEditTermsAndConditionApi,
  GamewiseBetListApi,
  addEditGameBetApiHandler,
  addEditPrivacyPolicyApi,
  getGameRulesApi,
  deleteGameRulesApi,
  AllRoomListApi,
  adminUserChatApi,
  walletlistApi,
  getUserWiseBetListApi,
  deleteGameWiseBetApi,
  deleteGameApi,
  callReqApi,
  dailyBonusApi,
  getBalanceReportApi,
  editProfileApi
} from "../../services/AdminIndex.js";

export const Signup = (params, navigate) => {
  return async function (dispatch) {
    signupApiHandler(params).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.SIGNUP, payload: data });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

export const login = (params, navigate) => {
  return async function (dispatch) {
    loginApi(params).then((data) => {
      console.log(data, 25);
      if (data.status === 200) {
        dispatch({ type: type.LOGIN, payload: data });
        setTimeout(() => {
          navigate("/admin/dashbord");
        }, 2000);
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

export const logout = (navigate) => {
  return async function (dispatch) {
    dispatch({ type: type.LOGOUT });
  };
};

export const AllUserList = (token) => {
  return async function (dispatch) {
    getAllUserApi(token).then((res) => {
      console.log(res, 52);
      if (res.status === "success" || res.status === 200) {
        dispatch({ type: type.ALL_USER, payload: res });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: res });
      }
    });
  };
};

export const AllTransactionList = (token) => {
  return async function (dispatch) {
    getTransactionApi(token).then((res) => {
      console.log(res, 52);
      if (res.status === "success" || res.status === 200) {
        dispatch({ type: type.ALL_TRANSACTION, payload: res });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: res });
      }
    });
  };
};


export const activeDeactiveUser = (params, navigate) => {
  return async function (dispatch) {
    activeDeactiveUserApi(params).then((res) => {
      if (res.status === 201 || res.status === 200) {
        dispatch({ type: type.ACTIVE_DEACTIVE_USER, payload: res });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: res });
      }
    });
  };
};

export const addEditGame = (params, navigate) => {
  return async function (dispatch) {
    addEditGameApi(params).then((res) => {
      if (res.status === 201 || res.status === 202) {
        dispatch({ type: type.CREATE_UPDATE_GAME, payload: res });
        setTimeout(() => {
          navigate("/admin/game-list");
        }, 1000);
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: res });
      }
    });
  };
};

export const getAllGameList = (navigate) => {
  return async function (dispatch) {
    getAllGamesApi().then((res) => {
      console.log(res, 52);
      if (res.status === "success" || res.status === 200) {
        dispatch({ type: type.ALL_GAME_LIST, payload: res });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: res });
      }
    });
  };
};

export const gameWiseBetList = (token, navigate) => {
  console.log(token , 112)
  return async function (dispatch) {
    GamewiseBetListApi(token).then((data) => {
      console.log(data , 115)
      if (data.status === 200) {
        dispatch({ type: type.GAME_WISE_BET_LIST, payload: data });
        // setTimeout(() => {
        //   navigate("/");
        // }, 2000);
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

export const addEditGameBet = (params , token, navigate) => {
  return async function (dispatch) {
    addEditGameBetApiHandler(params , token).then((data) => {
      if (data.status === 200 || data.status === 201) {
        dispatch({ type: type.ADD_EDIT_GAME_BET, payload: data });
        setTimeout(() => {
          navigate("/admin/game-wise-bet");
        }, 2000);
      } else {
        if(data.status === 400){
          console.log("In")
          dispatch({ type: type.ADD_EDIT_GAME_BET, payload: data });
        } 
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

export const addEditPrivacyPolicy = (params, token) => {
  return async function (dispatch) {
    addEditPrivacyPolicyApi(params, token).then((data) => {
      console.log(data, 121);
      if (data.status === 201) {
        dispatch({ type: type.ADD_EDIT_PRIVACY_POLICY, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

export const addEditTermsAndCodition = (params, token) => {
  return async function (dispatch) {
    addEditTermsAndConditionApi(params, token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_EDIT_TERMS_AND_CONDITION, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

export const CmsDetails = () => {
  return async function (dispatch) {
    cmsDetailsApi().then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.GET_CMS_DETAILS, payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

export const addUpdateGameRules = (params , token , navigate) => {
  return async function (dispatch) {
    addUpdateGameRulesApi(params , token).then((data) => {
      if (data.status === 201) {
        dispatch({ type: type.ADD_UPDATE_GAME_RULES , payload: data });
        navigate('/admin/game-rules')
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
};

export const getGameRules = (params) => {
  return async function (dispatch) {
    getGameRulesApi(params).then((data) => {
      console.log(data , 190)
      if (data.status === 200) {
        dispatch({ type: type.GET_GAME_RULES , payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
}

export const deleteGameRules = (params , token) => {
  return async function (dispatch) {
    deleteGameRulesApi(params, token).then(data => {
      if (data.status === 201) {
        dispatch({ type: type.DELETE_GAME_RULES , payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    })
  }
}

export const AllRoomList = (params, token) => {
  return async function (dispatch){
    AllRoomListApi(params, token).then((data) => {
      console.log(data.data , 218)
      if (data.status === 202) {
        dispatch({ type: type.ROOM_LIST , payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }

    })
  }
}


export const adminUserChat = (params , navigate , user) => {
  return async function (dispatch) {
    adminUserChatApi(params).then((data) => {
      // console.log(data , 234 , userId , `/admin/chat/${data.data.roomID}/${userId}`)
      if (data.status === 200 || data.status === 201) {
        dispatch({ type: type.CHAT_API , payload: data });
        const userId = user?.userId;
   
        if(userId)
        {
          
        navigate(`/admin/chat/${data.data.roomID}/${userId}`, { state : {user}})
        }
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    });
  };
} 

export const walletList =  (token) => {
  return async function(dispatch) {
    walletlistApi(token).then((data) => {
      console.log(data , "himanshudata")
      if (data.status === "success") {
        dispatch({ type: type.WALLET_LIST , payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    })
  }
}

export const userWisebetList = (token) => {
  return async function(dispatch){
    getUserWiseBetListApi(token).then((data) => {
      if (data.status === 200) {
        dispatch({ type: type.USER_WISE_BET_LIST , payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    })
  } 
}

export const deleteGameWiseBet = (params , token) => {
  return async function (dispatch) {
    deleteGameWiseBetApi(params, token).then(data => {
      if (data.status === 201) {
        dispatch({ type: type.DELETE_GAME_WISE_BET , payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    })
  }
}


export const deleteGame = (params , token) => {
  return async function (dispatch) {
    deleteGameApi(params, token).then(data => {
      if (data.status === 201) {
        dispatch({ type: type.DELETE_GAME , payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    })
  }
}

export const callReqList = (token) => {
  return async function (dispatch) {
    callReqApi(token).then(data => {
      if (data.status === 200) {
        dispatch({ type: type.CALL_REQUEST_LIST , payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    })
  }
}
export const getdailyBonus = (token) => {
  return async function (dispatch) {
    dailyBonusApi(token).then(data => {
      console.log(data , "data213")
      
    })
  }
}

export const getBalanceReport = (parmas, token) => {
  return async function (dispatch) {
    getBalanceReportApi(parmas, token).then(data => {
      if (data.status === 200) {
        dispatch({ type: type.BALANCE_REPORT , payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    })
  }
}

export const editProfile = (parmas, token) => {
  return async function (dispatch) {
    editProfileApi(parmas , token).then(data => {
      if (data.status === 200) {
        dispatch({ type: type.EDIT_PROFILE , payload: data });
      } else {
        dispatch({ type: type.ADMIN_ERROR_HANDLER, payload: data });
      }
    })
  }
}

