import { TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllTransactionList } from "../../../../redux/auth/action";
import Index from "../../../Index";
import { ToastContainer } from "react-toastify";


export default function TransactionList() {
    const dispatch = useDispatch();

    const [filterData, setFilterData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const transactionList = useSelector((state) => {
        return state.adminReducer.transactionList;
    });


    console.log("alert", transactionList)

    const token = useSelector((state) => {
        return state.adminReducer.Token;
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    useEffect(() => {
        setFilterData(transactionList);
    }, [TransactionList]);


    const handleSearch = (event) => {
        const result = transactionList.filter((item) => {
            console.log(item?.userName?.firstName.toLowerCase(), "item?.firstName")
            return (
                item?.userName?.firstName?.toLowerCase().includes(event?.target?.value?.toLowerCase()) ||
                (new Date(item?.createdAt)?.toLocaleDateString("en-GB"))?.toString().includes(event?.target?.value?.toLowerCase()) ||
                (new Date(item?.date)?.toLocaleDateString("en-GB"))?.toString().includes(event?.target?.value?.toLowerCase()) ||
                item?.transactionId?.toString().includes(event?.target?.value?.toLowerCase()) ||
                item?.amount?.toString().includes(event?.target?.value?.toLowerCase()) ||
                item?.mobile?.toString().includes(event?.target?.value?.toLowerCase())
            );
        });

        console.log(result, "result", transactionList)
        setFilterData(result);
        setPage(0);
    };

    useEffect(() => {
        dispatch(AllTransactionList(token));
    }, []);

    // console.log("filterData",filterData)
    return (
        <>
            <Index.Box className="dashboard-content">
                <Index.Box className="user-list-flex">
                    <Index.Typography
                        className="admin-page-title user-list-page-title"
                        component="h2"
                        variant="h2"
                    >
                        Transction Histroy
                    </Index.Typography>
                    <ToastContainer />
                    <Index.Box className="transactionList-btn-flex">
                        <Index.Box className="user-search-main">
                            <Index.Box className="user-search-box">
                                <Index.Box className="form-group">
                                    <Index.TextField
                                        fullWidth
                                        id="fullWidth"
                                        className="form-control"
                                        placeholder="Search"
                                        onChange={(e) => handleSearch(e)}
                                    />

                                    <img
                                        src={Index.Svg.search}
                                        className="search-grey-img"
                                        alt="search grey img"
                                    ></img>
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>

                    </Index.Box>
                </Index.Box>
                <Index.Box className="admin-dashboard-list-row">
                    <Index.Box sx={{ width: 1 }} className="grid-main">
                        <Index.Box
                            display="grid"
                            className="display-row"
                            gridTemplateColumns="repeat(12, 1fr)"
                            gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                        >
                            <Index.Box
                                gridColumn={{
                                    xs: "span 12",
                                    sm: "span 12",
                                    md: "span 12",
                                    lg: "span 12",
                                }}
                                className="grid-column"
                            >
                                <Index.Box className="admin-dash-box">
                                    <Index.Box className="transactionList-table-main transactionList-table-main2 page-table-main">
                                        <Index.TableContainer
                                            component={Index.Paper}
                                            className="table-container"
                                        >
                                            <Index.Table
                                                sx={{ minWidth: 650 }}
                                                aria-label="simple table"
                                                className="table"
                                            >
                                                <Index.TableHead className="table-head">
                                                    <Index.TableRow className="table-row">
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >
                                                            USER ID
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >
                                                            USERNAME
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >
                                                            AMOUNT
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >
                                                            TRANSACTION ID
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >

                                                            PAYMENT
                                                        </Index.TableCell>
                                                       
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >

                                                            DATE
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >

                                                            TIME
                                                        </Index.TableCell>
                                                        <Index.TableCell
                                                            component="th"
                                                            variant="th"
                                                            className="table-th"
                                                        >
                                                            STATUS
                                                        </Index.TableCell>

                                                    </Index.TableRow>
                                                </Index.TableHead>
                                                <Index.TableBody className="table-body">
                                                    {filterData?.length > 0 ?
                                                        filterData
                                                            .slice(
                                                                page * rowsPerPage,
                                                                page * rowsPerPage + rowsPerPage
                                                            )
                                                            .map((row) => {
                                                                console.log("hellos", row)
                                                                return (
                                                                    <Index.TableRow
                                                                        key={row.name}
                                                                        sx={{
                                                                            "&:last-child td, &:last-child th": {
                                                                                border: 0,
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.No ? row.No : "-"}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.userName?.firstName ? row?.userName?.firstName : "-"}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.amount ? row?.amount : "-"}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.transactionId ? row?.transactionId : "-"}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.paymentMethod ? row?.paymentMethod : "-"}
                                                                        </Index.TableCell>
                                                                        

                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.date ? new Date(row?.date)?.toLocaleDateString("en-GB") : "-"}
                                                                        </Index.TableCell>

                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.time ? row?.time : "-"}
                                                                        </Index.TableCell>
                                                                        <Index.TableCell
                                                                            component="td"
                                                                            variant="td"
                                                                            scope="row"
                                                                            className="table-td"
                                                                        >
                                                                            {row?.status ? row?.status : "-"}
                                                                        </Index.TableCell>

                                                                    </Index.TableRow>
                                                                );
                                                            }) : (
                                                            <Index.TableRow>
                                                                <Index.TableCell
                                                                    component="td"
                                                                    variant="td"
                                                                    scope="row"
                                                                    className="table-td"
                                                                >
                                                                    No Record found
                                                                </Index.TableCell>
                                                                <Index.TableCell></Index.TableCell>
                                                                <Index.TableCell></Index.TableCell>
                                                                <Index.TableCell></Index.TableCell>
                                                                <Index.TableCell></Index.TableCell>
                                                                <Index.TableCell></Index.TableCell>
                                                                <Index.TableCell></Index.TableCell>
                                                                <Index.TableCell></Index.TableCell>
                                                                <Index.TableCell></Index.TableCell>
                                                            </Index.TableRow>
                                                        )

                                                    }
                                                </Index.TableBody>
                                            </Index.Table>
                                        </Index.TableContainer>
                                    </Index.Box>
                                </Index.Box>{" "}
                                <Index.Box className="pagination-main">
                                    <TablePagination
                                        component="div"
                                        rowsPerPageOptions={[filterData?.length % 10]}
                                        count={filterData?.length}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Index.Box>
                            </Index.Box>
                        </Index.Box>
                    </Index.Box>
                </Index.Box>
            </Index.Box>

        </>
    );
}
