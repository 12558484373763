import React from 'react'
import Index from '../../../../../component/Index';
import DataService from '../../../../../config/DataService';
import { Api } from '../../../../../config/Api';
import { useState } from 'react';
import { useEffect } from 'react';

const WinLoseratio = ({id}) => {

  const [winloseratio, setWinLoseRatio] = useState({});

  const getWinLoseRatio = async () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("userId", id);
    await DataService.post(Api.User.WinLoseRatio, urlencoded).then((res) => {
      setWinLoseRatio(res.data.data);
    });
  };

  useEffect(() => {
    getWinLoseRatio();
  },[])
  return (
    <div>
        <Index.Box className="win-lose-main">
            <Index.Box className="card-main-head">
              Win-lose Ratio Till Date
            </Index.Box>
            <Index.Box className="card-main-body">
              <Index.Box className="card-total">
                <Index.Typography className="left-name">
                  Total Game
                </Index.Typography>
                <Index.Typography className="right-data">
                  {winloseratio?.totalGamesPlayed
                    ? winloseratio?.totalGamesPlayed
                    : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="card-total card-total-sub">
                <Index.Typography className="left-name">
                  Win/Loss
                </Index.Typography>
                <Index.Typography className="right-data">
                  {winloseratio?.totalWins && winloseratio?.totalWins
                    ? winloseratio?.totalWins + "/" + winloseratio?.totalLosses
                    : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="card-total card-total-sub">
                <Index.Typography className="left-name">
                  Win Ratio
                </Index.Typography>
                <Index.Typography className="right-data">
                  {winloseratio?.totalWins && winloseratio?.totalWins
                    ? ((winloseratio?.totalWins /
                        winloseratio?.totalGamesPlayed) *
                      100).toFixed(3)
                    : 0}
                  %
                </Index.Typography>
              </Index.Box>
              <Index.Box className="card-total card-total-sub">
                <Index.Typography className="left-name">
                  Loss Ratio
                </Index.Typography>
                <Index.Typography className="right-data">
                  {winloseratio?.totalLosses && winloseratio?.totalWins
                    ? ((winloseratio?.totalLosses /
                        winloseratio?.totalGamesPlayed) *
                      100).toFixed(3)

                    : 0}
                  %
                </Index.Typography>
              </Index.Box>
            </Index.Box>

            <Index.Box className="card-main-head">
              Today's Win-lose Ratio
            </Index.Box>
            <Index.Box className="card-main-body">
              <Index.Box className="card-total">
                <Index.Typography className="left-name">
                  Total Game
                </Index.Typography>
                <Index.Typography className="right-data">
                  {winloseratio?.gamesPlayedToday
                    ? winloseratio?.gamesPlayedToday
                    : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="card-total card-total-sub">
                <Index.Typography className="left-name">
                  Win/Loss
                </Index.Typography>

                <Index.Typography className="right-data">
                  {winloseratio?.gamesWinToday && winloseratio?.gamesLossToday
                    ? winloseratio?.gamesWinToday +
                      "/" +
                      winloseratio?.gamesLossToday
                    : 0}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="card-total card-total-sub">
                <Index.Typography className="left-name">
                  Win Ratio
                </Index.Typography>
                <Index.Typography className="right-data">
                  {winloseratio?.gamesWinToday && winloseratio?.gamesPlayedToday
                    ? ((winloseratio?.gamesWinToday /
                        winloseratio?.gamesPlayedToday) *
                      100).toFixed(3)

                    : 0}
                  %
                </Index.Typography>
              </Index.Box>

              <Index.Box className="card-total card-total-sub">
                <Index.Typography className="left-name">
                  Loss Ratio
                </Index.Typography>
                <Index.Typography className="right-data">
                  {winloseratio?.gamesLossToday &&
                  winloseratio?.gamesPlayedToday
                    ? ((winloseratio?.gamesLossToday /
                        winloseratio?.gamesPlayedToday) *
                      100).toFixed(3)

                    : 0}
                  %
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
    </div>
  )
}

export default WinLoseratio