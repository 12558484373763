import React, { useState , useEffect } from "react";
import Index from "../../../../component/Index";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useDispatch, useSelector } from "react-redux";
import { addEditPrivacyPolicy, CmsDetails } from "../../../../redux/auth/action";
import { ToastContainer } from "react-toastify";
import ErrorShow from "../../../../component/common/ErrorShow";



export default function PrivacyPolicy() {
 

  const dispatch = useDispatch();
  const [description, setDiscription] = useState("");
  const [descriptionError, setDiscriptionError] = useState("");

  const CMS = useSelector((state) => {
    return state.adminReducer.Cms;
  });

  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });


  useEffect(() => {
    dispatch(CmsDetails())
  },[])

  useEffect(() => {
    setDiscription(CMS?.[0]?.privacyPolicy?.description)
  },[CMS])



  const handleSubmit = () => {
    if (description == "") {
      setDiscriptionError("Please Enter Description");
    } else {
      setDiscriptionError("");
    }

    if (description !== "") {
      const urlencoded = new URLSearchParams();
      urlencoded.append("description", description);
      dispatch(addEditPrivacyPolicy(urlencoded, token));
    }
  };
  return (
    <>
      <Index.Box className="dashboard-content">
      <ToastContainer />
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Privacy Policy
          </Index.Typography>
        </Index.Box>
        <Index.Box>
        
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Description
            </Index.FormHelperText>
            <Index.Box className="set-ck-editor">
            <CKEditor
              editor={ClassicEditor}
              data={CMS?.[0]?.privacyPolicy ? CMS?.[0]?.privacyPolicy?.description : "-"}
              onReady={(editor) => {
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();

                setDiscription(data);
                setDiscriptionError("");
              }}
              onBlur={(event, editor) => {
                console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                console.log("Focus.", editor);
              }}
            />
            </Index.Box>
            <ErrorShow error={descriptionError} />
          </Index.Box>
          <Index.Box className="save-btn-main border-btn-main">
            <Index.Button
              className="save-user-btn border-btn"
              onClick={() => handleSubmit()}
            >
              <img src={Index.Svg.save} className="user-save-icon"></img>Save
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
