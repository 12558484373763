import { SwipeableDrawer, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { callReqList, getBalanceReport } from "../../../../redux/auth/action";
import Index from "../../../Index";
import FileSaver from "file-saver";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function BalanceReport() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalBalance, setTotalBalance] = useState(0);

  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });

  const BalanceDetails = useSelector((state) => {
    return state.adminReducer.BalanceReport;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    const urlencoded = new URLSearchParams();
    dispatch(getBalanceReport(urlencoded, token));
  }, []);

  const adminBalance = (arr) => {
    let sum = arr?.reduce((accum,item) => accum + item?.adminamount, 0)
    setTotalBalance(sum)
  }

  useEffect(() => {
    if(BalanceDetails?.length > 0) {
      adminBalance(BalanceDetails);
    }
  },[BalanceDetails])

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            Balance Report
          </Index.Typography>
          <Index.Box className="userlist-btn-flex call-userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box call-req-list-search">
                <Index.Box className="form-group">
                  {/* <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search user"
                    onChange={(e) => handleSearch(e.target.value)}
                  />

                  <img
                    src={Index.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img> */}

                  <Index.Box className="adduser-btn-main btn-main-primary exp-btn">
                    <Index.Button
                      className="adduser-btn btn-primary"
                      // onClick={() => {
                      //   let curDate = moment(new Date()).format('DD-MM-YYYY')
                      //   exportAll(exportDetails , `Call Request List (${curDate})`)
                      // }}
                    >
                      Total Balance : {totalBalance}
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Game
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              User
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              BetAmount
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Winner
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Winning Amount
                            </Index.TableCell>

                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              Admin Received Amount
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {BalanceDetails?.length > 0 ? (
                            BalanceDetails.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            ).map((row) => {
                              
                              return (
                                <Index.TableRow
                                  key={row.name}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    {row?.Game}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    {row?.user}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {Math.abs(row?.betAmount)}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row?.winner}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {Math.abs(row?.winningPrice)}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {Math.abs(row?.adminamount)}
                                  </Index.TableCell>
                                </Index.TableRow>
                              );
                            })
                          ) : (
                            <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                No Record found
                              </Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                            </Index.TableRow>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[BalanceDetails?.length % 10]}
                    count={BalanceDetails?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
