import React from "react";
import { useParams } from "react-router-dom";
import Index from "../../../../Index";

import { useMemo } from "react";
import WinLoseratio from "./WinLoseratio";
import TotalEarnedCoin from "./TotalEarnedCoin";
import PlayedTotalGame from './TotalGamePlayed'

export default function UserDetails() {
  const params = useParams();

  const winLoseRatio = useMemo(() => <WinLoseratio id={params?.id} />, []);
  const TotalEarnCoin = useMemo(() => <TotalEarnedCoin id={params?.id} />, []);
  const TotalGamePlay = useMemo(() => <PlayedTotalGame id={params?.id} />, [])

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            User Details
          </Index.Typography>
        </Index.Box>

        <Index.Box className="user-cards">
          {winLoseRatio}
          {TotalEarnCoin}
        </Index.Box>
        {TotalGamePlay}
      </Index.Box>
    </>
  );
}
