import axios from "axios";
const BASE_URL = "http://35.177.56.74:3016/api";
// const BASE_URL = "http://localhost:3016/api";

var token = localStorage.getItem('token');

const header = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    auth: token,
  },
};

const DataService = axios.create({
  baseURL: BASE_URL,
  headers: header.headers,
});

export default DataService;
