import React, { useState } from "react";
import Index from "../Index";
import { useForm } from "react-hook-form";
import { login } from "../../redux/auth/action";
import { ToastContainer } from "react-toastify";
import ErrorShow from "../../component/common/ErrorShow";

const Login = () => {

  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();

  // Redux Data
  const token = Index.useSelector((state) => {
    return state.adminReducer.Token;
  });

  // All State
  const [showPassword, setShowPassword] = useState(false);


  // Methods
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,10}$/;

  const submit = (data) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", data.email);
    urlencoded.append("password", data.password);
    dispatch(login(urlencoded, navigate));
    localStorage.setItem('token', token);
  };

  

  return (
    <div>
      <Index.Box className="admin-login-main-flex">
        <Index.Box className="admin-login-left-main set-login-box-back">
          <Index.Box className="login-left-bg">
            <img src={Index.Png.ss_logo} className=""></img>
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-login-right-main">
          <Index.Box className="admin-login-box">
            <Index.Box className="admin-login-main">
              <form className="form-content" onSubmit={handleSubmit(submit)}>
                <Index.Box className="admin-login-inner set-login-box">
                  <Index.Typography
                    component="h2"
                    variant="h2"
                    className="admin-wel-text"
                  >
                    Log In
                  </Index.Typography>
                  <Index.Typography
                    component="p"
                    variant="p"
                    className="admin-sign-para common-para"
                  >
                    Please enter your credentials to log in!
                  </Index.Typography>
                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      Email
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.TextField
                        fullWidth
                        id="fullWidth"
                        className="form-control"
                        name="email"
                        {...register("email", {
                          required: "Email  is Required.",
                        })}
                        error={Boolean(errors.email)}
                        helperText={errors?.email?.message}
                      />
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="input-box">
                    <Index.FormHelperText className="form-lable">
                      Password
                    </Index.FormHelperText>
                    <Index.Box className="form-group">
                      <Index.OutlinedInput
                        className="form-control-eye"
                        id="outlined-adornment-password"
                        name="password"
                        {...register("password", {
                          required: "Password is Required",
                          pattern: {
                            value: PWD_REGEX,
                            message:
                              "Password should be 8-10 characters and include at least 1 uppercase letter, 1 number and 1 special character!",
                          },
                        })}
                        error={!!errors?.password}
                        helperText={
                          errors?.password ? errors.password.message : null
                        }
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <Index.InputAdornment position="end">
                            <Index.IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <Index.VisibilityOff />
                              ) : (
                                <Index.Visibility />
                              )}
                            </Index.IconButton>
                          </Index.InputAdornment>
                        }
                      />
                      <ErrorShow error={errors?.password?.message}/>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="flex-all forgot-row">
                    <Index.Box className="checkbox-main">
                      <Index.FormControlLabel
                        control={<Index.Checkbox defaultChecked />}
                        label="Remember Me"
                        className="checkbox-lable"
                      />
                    </Index.Box>
                    <Index.Link
                      className="text-decoration-none admin-forgot-para common-para"
                      href="/forgot-password"
                    >
                      Forgot Password?
                      {/* <Index.Typography component="p" variant='p' className='admin-forgot-para common-para'>Forgot Password?</Index.Typography> */}
                    </Index.Link>
                  </Index.Box>
                  <Index.Box className="btn-main-primary login-btn-main">
                    <Index.Button
                      className="btn-primary login-btn"
                      type="submit"
                    >
                      Log In
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <ToastContainer />
      </Index.Box>
    </div>
  );
};

export default Login;
