import React from 'react'
import DataService from '../../../../../config/DataService';
import { Api } from '../../../../../config/Api';
import { useState } from 'react';
import moment from 'moment';
import ErrorShow from '../../../../../component/common/ErrorShow';
import Index from '../../../../Index';
import { useEffect } from 'react';

const TotalGamePlayed = ({id}) => {

  
  const [selectData, setSelectData] = useState(1);
  const [totalGamePlayed, setTotalGamePlayed] = useState([]);
  const [FilterData, setFilterData] = useState([]);
  const [totalLast30daysGame, setTotalLast30daysGame] = useState([]);

  const handleSearch = (value) => {
    const result = (
      selectData == 1 ? totalGamePlayed : totalLast30daysGame
    ).filter((item) => {
      return (
        item.gameName.toLowerCase().includes(value.toLowerCase()) ||
        item.date.includes(value)
      );
    });
    setFilterData(result);
  };



  const getDateWiseList = async (value) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("date", value);

    await DataService.post(Api.User.dateWiseList, urlencoded).then((res) => {
      setTotalGamePlayed(res.data.data.totalGamePlayed);
      selectData == 1
        ? setFilterData(res.data.data.totalGamePlayed)
        : setFilterData(res.data.data.totalGamePlayedIn30days);
      setTotalLast30daysGame(res.data.data.totalGamePlayedIn30days);
    });
  }; 

  useEffect(() => {
    let currDate = moment(new Date()).format("YYYY-MM-DD");
    if(id){
      getDateWiseList(currDate);
    }
  },[])


  return (
    <div>
        <Index.Box className="all-user-data">
          <Index.Box className="card-main-head">Total Game Played</Index.Box>
          <Index.Box className="set-search-btn">
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DatePicker
                  className="set-date-btn"
                  // format="DD/MM/YYYY"
                  label='Select Month'
                  views={['month', 'year']}
                  onChange={(e) =>{
                    handleSearch(moment(e.$d).format("YYYY-MM"))
                    // getDateWiseList(moment(e.$d).format("YYYY-MM-DD"))
                  }}
                />
              </DemoItem>
            </LocalizationProvider> */}
            <Index.Box className="dropdown-box input-box modal-input-box">
              <Index.FormControl className="form-control">
                <Index.Select
                  sx={{
                    "& .MuiFormHelperText-root": {
                      ml: 0,
                      mt: "5px",
                    },
                  }}
                  //defaultValue={props.row ? props.row.cuisine : ""}
                  className="dropdown-select"
                  id="gameName"
                  value={selectData}
                  onChange={(e) => setSelectData(e.target.value)}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <Index.MenuItem value={1}>Total</Index.MenuItem>
                  <Index.MenuItem value={2}>Last 30 Days</Index.MenuItem>
                </Index.Select>

                {/* <span><img src={Svg.greydown} className="grey-down pay-down" alt='grey down arrow'></img></span> */}
              </Index.FormControl>
            </Index.Box>

            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search user"
                    onChange={(e) => handleSearch(e.target.value)}
                  />

                  <img
                    src={Index.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="main-date-user">
            <Index.Box className="Date">
              <Index.Box className="admin-dashboard-list-row">
                <Index.Box sx={{ width: 1 }} className="grid-main">
                  <Index.Box
                    display="grid"
                    className="display-row"
                    gridTemplateColumns="repeat(12, 1fr)"
                    gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
                  >
                    <Index.Box
                      gridColumn={{
                        xs: "span 12",
                        sm: "span 12",
                        md: "span 12",
                        lg: "span 12",
                      }}
                      className="grid-column"
                    >
                      <Index.Box className="">
                        <Index.Box className="gamewisebet-table user-table-card">
                          <Index.TableContainer
                            component={Index.Paper}
                            className="table-container"
                          >
                            <Index.Table
                              aria-label="simple table"
                              className="table"
                            >
                              <Index.TableHead className="table-head">
                                <Index.TableRow className="table-row">
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                  >
                                    Date
                                  </Index.TableCell>

                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="right"
                                  >
                                    GameName
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="right"
                                  >
                                    Bet Amount
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="right"
                                  >
                                    Winning Price
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="th"
                                    variant="th"
                                    className="table-th"
                                    align="right"
                                  >
                                    Lossing Price
                                  </Index.TableCell>
                                </Index.TableRow>
                              </Index.TableHead>

                              {/* table data */}
                              <Index.TableBody className="table-body">
                                {FilterData.length > 0 ? (
                                  FilterData?.map((item) => {
                                    return (
                                      <>
                                        <Index.TableRow
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            scope="row"
                                            className="table-td"
                                            align="justify"
                                          >
                                            {moment(item?.date).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </Index.TableCell>

                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td last-col"
                                          >
                                            {item?.gameName}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td last-col"
                                          >
                                            {item?.betAmount}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td last-col"
                                            sx={{
                                              color:
                                                item?.winningAmount > 0
                                                  ? "green"
                                                  : "",
                                            }}
                                          >
                                            {Math.abs(item?.winningAmount)}
                                          </Index.TableCell>
                                          <Index.TableCell
                                            component="td"
                                            variant="td"
                                            className="table-td last-col"
                                            sx={{
                                              color:
                                                item?.lossingAmount > 0
                                                  ? "red"
                                                  : "",
                                            }}
                                          >
                                            {Math.abs(item?.lossingAmount)}
                                          </Index.TableCell>
                                        </Index.TableRow>
                                      </>
                                    );
                                  })
                                ) : (
                                  <>
                                    <Index.TableRow
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        scope="row"
                                        className="table-td"
                                        align="justify"
                                      >
                                        No Record Found
                                      </Index.TableCell>

                                      <Index.TableCell
                                        component="td"
                                        variant="td"
                                        className="table-td last-col"
                                      ></Index.TableCell>
                                    </Index.TableRow>
                                  </>
                                )}
                              </Index.TableBody>

                              {/* table data end */}
                            </Index.Table>
                          </Index.TableContainer>
                        </Index.Box>
                      </Index.Box>{" "}
                      {/* <Index.Box className="pagination-main">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[AllGameList?.length % 5]}
                    count={AllGameList?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Index.Box> */}
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
    </div>
  )
}

export default TotalGamePlayed ;