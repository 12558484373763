
import './App.css';
import Routers from './routes/Routes';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/admin.css';
import './assets/css/responsive.css';



function App() {
  return (
    <div className="App">
      <Routers/>
    </div>
  );
}

export default App;
