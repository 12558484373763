import { SwipeableDrawer, TablePagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { activeDeactiveUser, adminUserChat, AllUserList } from "../../../../redux/auth/action";
import Index from "../../../Index";
import MessageIcon from '@mui/icons-material/Message';
import { ToastContainer } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};


const IOSSwitch = Index.styled((props) => (
  <Index.Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",

      
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#000000",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 14,
    height: 14,
    boxShadow: "none",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function UserList() {
 

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(true);
  const [userList, setUserList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const AllUseList = useSelector((state) => {
    return state.adminReducer.allUser;
  });
  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });
  const adminData = useSelector((state) => {
    return state.adminReducer.login;
  });
 
 
  const handleClose = () => setOpen(false);
  const handleCloseDelete = () => setOpenDelete(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    setUserList(AllUseList);
    setFilterData(AllUseList);
  }, [AllUseList]);


  const handleSearch = (event) => {
    const result = userList.filter((item) => {
      console.log(item?.firstName.toLowerCase() , "item?.firstName")
      return (
        item?.firstName?.toLowerCase().includes(event?.target?.value?.toLowerCase()) ||
        item?.lastName?.toLowerCase().includes(event?.target?.value?.toLowerCase()) ||
        item?.email?.toLowerCase().includes(event?.target?.value?.toLowerCase())
      );
    });

    console.log(result , "result" , userList)
    setFilterData(result);
    setPage(0);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  

  

  
 

 
  const handleactive = (data) => {
    console.log(data?.status , 162)
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", data?.id);
    urlencoded.append("action", data?.status);
    dispatch(activeDeactiveUser(urlencoded));
  };

  useEffect(() => {
    dispatch(AllUserList(token));
  },[]);


  const adminChat = (userId , username , row) => {
    localStorage.setItem('userName' , username);
    const urlencoded = new URLSearchParams();
    urlencoded.append("from", adminData?.adminId);
    urlencoded.append("to", userId);
    dispatch(adminUserChat(urlencoded , navigate, row));
  }

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Box className="user-list-flex">
          <Index.Typography
            className="admin-page-title user-list-page-title"
            component="h2"
            variant="h2"
          >
            User List
          </Index.Typography>
          <ToastContainer />
          <Index.Box className="userlist-btn-flex">
            <Index.Box className="user-search-main">
              <Index.Box className="user-search-box">
                <Index.Box className="form-group">
                  <Index.TextField
                    fullWidth
                    id="fullWidth"
                    className="form-control"
                    placeholder="Search user"
                    onChange={(e) => handleSearch(e)}
                  />

                  <img
                    src={Index.Svg.search}
                    className="search-grey-img"
                    alt="search grey img"
                  ></img>
                </Index.Box>
              </Index.Box>
            </Index.Box>
{/* 
            <Index.Box className="filter-main">
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <SwipeableDrawer
                    className="filter-main"
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Index.Box className="filter-header">
                      <Index.Typography className="filter-title">
                        User Filter
                      </Index.Typography>
                      <img
                        src={Index.Png.close}
                        className="filter-close-icon"
                      />
                    </Index.Box>
                    <Index.Box className="filter-inner-main">
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          Name
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-box filter-input-box">
                        <Index.FormHelperText className="form-lable">
                          City
                        </Index.FormHelperText>
                        <Index.Box className="form-group">
                          <Index.TextField
                            fullWidth
                            id="fullWidth"
                            className="form-control"
                            placeholder=""
                          />
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="checkbox-main filter-checkbox-main input-box">
                        <Index.FormHelperText className="form-lable">
                          Status
                        </Index.FormHelperText>
                        <Index.FormControlLabel
                          control={<Index.Checkbox defaultChecked />}
                          label="Active"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Deactivate"
                          className="checkbox-lable"
                        />
                        <Index.FormControlLabel
                          control={<Index.Checkbox />}
                          label="Pending"
                          className="checkbox-lable"
                        />
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="filter-footer">
                      <Index.Box className="filter-btn-main border-btn-main btn-main-primary">
                        <Index.Button className="border-btn filter-cancel-btn">
                          Cancel
                        </Index.Button>
                        <Index.Button className="btn-primary filter-btn">
                          Filter
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </Index.Box> */}

            {/* <Index.Box className="flex-all user-list-inner-flex">
              <Index.Box className="adduser-btn-main btn-main-primary">
                <Index.Button
                  className="adduser-btn btn-primary"
                  onClick={handleOpen}
                >
                  <img
                    src={Index.Svg.plus}
                    className="plus-icon"
                    alt="plus icon"
                  />
                  Add User
                </Index.Button>
              </Index.Box>
            </Index.Box> */}
          </Index.Box>
        </Index.Box>
        <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 12",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="userlist-table-main userlist-table-main2 page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              USER ID
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              NAME
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              EMAIL
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              MOBILE NUMBER
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              REFERRAL CODE
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ACTION
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {filterData.length > 0  ? 
                          filterData
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              let username = row.firstName + " " + row.lastName
                              return (
                                <Index.TableRow
                                  key={row.name}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    {row.userId ? row.userId : "-"}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    scope="row"
                                    className="table-td"
                                  >
                                    {username ? username : '-'}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row.email ? row.email : '-'}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row.mobileNumber ? row.mobileNumber : '-'}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    {row.refferelCode ? row.refferelCode : '-'}
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <span
                                      style={{
                                        color:
                                          row.isActive == true
                                            ? "green"
                                            : "red",
                                      }}
                                    >
                                      {" "}
                                      {row.isActive == true
                                        ? "Active"
                                        : "DeActive"}
                                    </span>
                                  </Index.TableCell>
                                  <Index.TableCell
                                    component="td"
                                    variant="td"
                                    className="table-td"
                                  >
                                    <Index.Box className="userdata-btn-flex">
                                      {/* <Index.Button>
                                    <img src={Index.Svg.blueedit}></img>
                                  </Index.Button>
                                  <Index.Button>
                                    <img src={Index.Svg.trash}></img>
                                  </Index.Button> */}
                                      <Index.Button onClick={() => navigate(`/admin/user-details/${row._id}`)}>
                                        <img src={Index.Svg.yelloweye}></img>
                                      </Index.Button>
                                      <Index.Button
                                        
                                       onClick={() => {
                                        console.log(username , 782)
                                        adminChat(row.userId , username, row)
                                        }}>
                                        <MessageIcon />
                                      </Index.Button>
                                      <Index.Box className="switch-main">
                                      
                                        <Index.FormControlLabel
                                          control={
                                            <IOSSwitch 
                                              checked={row?.isActive}
                                              sx={{ m: 1 }}
                                              className="MuiSwitch-track-active"
                                            />
                                          }
                                          onClick={() => {
                                            const temp = {
                                              id: row?._id,
                                              status: !row?.isActive,
                                            };
                                            setActive(!active);

                                            handleactive(temp);
                                          }}
                                          className="switch-lable"
                                        />
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              );
                            }) : (
                              <Index.TableRow>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                No Record found
                              </Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                              <Index.TableCell></Index.TableCell>
                            </Index.TableRow>
                            )
                            
                            }
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>{" "}
                <Index.Box className="pagination-main">
                  <TablePagination
                    component="div"
                    rowsPerPageOptions={[filterData?.length % 10]}
                    count={filterData?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="modal"
      >
        <Index.Box sx={style} className="add-user-modal-inner-main modal-inner">
          <Index.Box className="modal-header">
            <Index.Typography
              id="modal-modal-title"
              className="modal-title"
              variant="h6"
              component="h2"
            >
              Add User Modal
            </Index.Typography>
            <img src={Index.Svg.close} className="modal-close-icon" />
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              First Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Last Name
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Email<span className="span-text common-para">(Optional)</span>
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="input-box modal-input-box">
            <Index.FormHelperText className="form-lable">
              Date
            </Index.FormHelperText>
            <Index.Box className="form-group">
              <Index.TextField
                fullWidth
                id="fullWidth"
                className="form-control"
                placeholder=""
              />
            </Index.Box>
          </Index.Box>
          <Index.Box className="modal-user-btn-flex">
            <Index.Box className="discard-btn-main border-btn-main">
              <Index.Button className="discard-user-btn border-btn">
                Discard
              </Index.Button>
            </Index.Box>
            <Index.Box className="save-btn-main border-btn-main">
              <Index.Button className="save-user-btn border-btn">
                <img src={Index.Svg.save} className="user-save-icon"></img>Save
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>

     
    </>
  );
}
