import { useEffect } from "react";

import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Index from "../../../Index";
import DataService from "../../../../config/DataService";
import { Api } from "../../../../config/Api";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getdailyBonus } from "../../../../redux/auth/action";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(
    "#95954",
    <Index.PaidLable />,
    "OCTOBER 25, 2022",
    "JEFF BREITENBERG",
    "4953"
  ),
  createData(
    "#95954",
    <Index.FailedLable />,
    "OCTOBER 25, 2022",
    "JEFF BREITENBERG",
    "4953"
  ),
  createData(
    "#95954",
    <Index.PendingLable />,
    "OCTOBER 25, 2022",
    "JEFF BREITENBERG",
    "4953"
  ),
  createData(
    "#95954",
    <Index.PaidLable />,
    "OCTOBER 25, 2022",
    "JEFF BREITENBERG",
    "4953"
  ),
  createData(
    "#95954",
    <Index.PaidLable />,
    "OCTOBER 25, 2022",
    "JEFF BREITENBERG",
    "4953"
  ),
];

export default function Home() {
  const [counting, showCounting] = useState({});
  const [totalRevenue , setTotalRevenue] = useState({});
  const [totalBalanceOfAllUser , setTotalBalanceOfAllUser] = useState();

  const dispatch = useDispatch();

  const token = useSelector((state) => {
    return state.adminReducer.Token;
  });

  useEffect(() => {
    DataService.get(Api.User.dashboard).then((res) => {
      showCounting(res.data.data);
    });
  }, []);

  useEffect(() => {
    DataService.get(Api.User.totalRevenue).then((res) => {
      console.log(res.data.data , "res")
      setTotalRevenue(res.data.data)
    })
  },[])

  useEffect(() => {
    DataService.get(Api.User.totalBalanceOfAllUser).then((res) => {
      console.log(res.data.data.totalBalance , "totalBalance")
      setTotalBalanceOfAllUser(res.data.data.totalBalance)
    })
  },[])

  // useEffect(() => {
  //   const now = new Date();
  //   const tomorrow = new Date(
  //     now.getFullYear(),
  //     now.getMonth(),
  //     now.getDate() + 1
  //   );
  //   const timeUntilTomorrow = tomorrow - now;

  //   const timeoutId = setTimeout(() => {
  //     dispatch(getdailyBonus(token));
  //   }, timeUntilTomorrow);

  //   return () => clearTimeout(timeoutId);
  // }, [dispatch, token]);

  return (
    <>
      <Index.Box className="dashboard-content">
        <Index.Typography
          className="admin-page-title"
          component="h2"
          variant="h2"
        >
          Dashboard
        </Index.Typography>
        <Index.Box className="admin-dashboad-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Users
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {counting?.totalUsers}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.dashicon1}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Room
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {counting?.totalRoom}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.dashicon2}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Active Room
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {counting?.totalActiveRoom}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.dashicon3}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Users Online
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {counting?.totalUsersOnline}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.dashicon4}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Revenue Till Date
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {totalRevenue?.revenueTillNow ? totalRevenue?.revenueTillNow/10 : 0}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.IndianRupee}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Revenue This Month
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {totalRevenue?.revenueCurrentMonth ? totalRevenue?.revenueCurrentMonth/10 : 0}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.IndianRupee}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 6",
                  md: "span 3",
                  lg: "span 3",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dashboard-box">
                  <Index.Box className="admin-dashboard-inner-box">
                    <Index.Box className="admin-dash-left">
                      <Index.Typography
                        className="admin-dash-text"
                        variant="p"
                        component="p"
                      >
                        Total Balance of all User(Rs.)
                      </Index.Typography>
                      <Index.Typography
                        className="admin-dash-price"
                        variant="h1"
                        component="h1"
                      >
                        {totalBalanceOfAllUser ? totalBalanceOfAllUser : 0}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="admin-dash-right">
                      <img
                        src={Index.Svg.IndianRupee}
                        className="dash-icons"
                        alt="dashboard icon"
                      />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        {/* <Index.Box className="admin-dashboard-list-row">
          <Index.Box sx={{ width: 1 }} className="grid-main">
            <Index.Box
              display="grid"
              className="display-row"
              gridTemplateColumns="repeat(12, 1fr)"
              gap={{ xs: 2, sm: 2, md: 0, lg: 0 }}
            >
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Latest Orders
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="view-btn-main border-btn-main">
                      <Index.Button
                        className="view-order-btn border-btn"
                        btnLabel="View Orders"
                      >
                        View Orders
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ORDER
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              DATE
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              CUSTOMER
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              TOTAL
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {rows.map((row) => (
                            <Index.TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row.name}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.calories}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.fat}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.carbs}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.protein}
                              </Index.TableCell>
                            </Index.TableRow>
                          ))}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box
                gridColumn={{
                  xs: "span 12",
                  sm: "span 12",
                  md: "span 12",
                  lg: "span 6",
                }}
                className="grid-column"
              >
                <Index.Box className="admin-dash-box">
                  <Index.Box className="flex-all admin-dashboard-table-row">
                    <Index.Box className="admin-sub-title-main">
                      <Index.Typography
                        className="admin-sub-title"
                        component="h3"
                        variant="h3"
                      >
                        Latest Orders
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="view-btn-main border-btn-main">
                      <Index.Button className="view-order-btn border-btn">
                        View Orders
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="page-table-main">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        className="table"
                      >
                        <Index.TableHead className="table-head">
                          <Index.TableRow className="table-row">
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              ORDER
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              STATUS
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              DATE
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              CUSTOMER
                            </Index.TableCell>
                            <Index.TableCell
                              component="th"
                              variant="th"
                              className="table-th"
                            >
                              TOTAL
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody className="table-body">
                          {rows.map((row) => (
                            <Index.TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <Index.TableCell
                                component="td"
                                variant="td"
                                scope="row"
                                className="table-td"
                              >
                                {row.name}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.calories}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.fat}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.carbs}
                              </Index.TableCell>
                              <Index.TableCell
                                component="td"
                                variant="td"
                                className="table-td"
                              >
                                {row.protein}
                              </Index.TableCell>
                            </Index.TableRow>
                          ))}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="progress-bar-content">
          <Index.Typography
            className="admin-page-title"
            component="h2"
            variant="h2"
          >
            Progress Bar
          </Index.Typography>
          <Index.Box className="progress-bar-main">
            <BorderLinearProgress
              variant="determinate"
              value={50}
              className="progress-bar"
            />
            <span className="progress-bar-text">50%</span>
          </Index.Box>
        </Index.Box> */}
      </Index.Box>
    </>
  );
}
